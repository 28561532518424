import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Grid } from '@mui/material';
import Container from '@mui/material/Container';
import UserFeedback from './translationOutput/feedbackComp/UserFeedback';
import TranslationOutput from './translationOutput/TranslationOutput';
import GiveFeedbackModal from './translationOutput/feedbackComp/GiveFeedbackModal';
import { useTranslation } from 'react-i18next';
import theme from '../../theme/themeConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import { isEmpty } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { UserSelector } from 'src/redux/user/selector';
import {
  checkInputLength,
  editorValueClear,
  editorValueUpdate,
  resetHighlight,
} from './helper';
import { emptyStateValueSynonyms } from 'src/redux/synonyms/actions';
import TranslateButtonComp from './translationInput/TranslateButtonComp';
import TranslationInputWraper from './translationInput/TranslationInputWraper';
import {
  emptyStateValueTrans,
  getTranslationConfig,
  setImagePageVisibility,
  translateLangTrigger,
} from 'src/redux/translation/actions';
import { TranslateSelector } from 'src/redux/translation/selector';
import { sideBarTab, triggerAlert } from 'src/redux/appSetting/actions';
import { HistorySelector } from 'src/redux/translationHistory/selector';
import { updateJobValue } from 'src/redux/translationHistory/actions';
import { AddImageSelector } from '../../redux/addImage/selector';
import { $getRoot, LexicalEditor } from 'lexical';
import { updateUserInfo } from 'src/redux/user/actions';
import ImageSelectionComp from '../addImage/ImageSelectionComp';
import { resetTableImages } from '../../redux/addImage/actions';
import TranslationStepSwitch from '../TranslationPage/TranslationStepSwitch';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

type Props = {
  inputText: any;
  setOpen(value: boolean): unknown;
  setOutputText(value: any): unknown;
  inputTextValue: any;
  outputTextValue: any;
  outputText: any;
  setInputText: Dispatch<SetStateAction<string>>;
  open: any;
};

export let outputEditor: null | LexicalEditor = null;
export let inputEditor: null | LexicalEditor = null;
const TextareaField = (props: Props) => {
  // states
  const dispatch: any = useDispatch();

  const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));
  //   const outputText = useRef('');
  const inputRefEditor: any = useRef(null);
  const outputRefEditor: any = useRef(null);
  // get outputLanguageType from local storage or set to default
  const [disableInputField, setDisableInputField] = useState(false);

  // states for user feedback
  const [showTooltipOutput, setShowTooltipOutput] = useState(false);
  const [showFeedbackButtons, setShowFeedbackButtons] = useState(false);
  const [giveFeedbackModalShow, setGiveFeedbackModalShow] = useState(false);
  // choose file or normal text input
  const [fileInput, setFileInput] = useState(false);

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const [isUpdated, setIsUpdated] = useState(false);
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );
  const loadingTranslation = useSelector(
    (state: any) => TranslateSelector(state).loadingTrans,
    shallowEqual
  );

  const heightlight = useSelector(
    (state: any) => AddImageSelector(state).heightlight,
    shallowEqual
  );

  const historyJob = useSelector(
    (state: any) => HistorySelector(state).historyJob,
    shallowEqual
  );

  const regeneratedJobs = useSelector(
    (state: any) => TranslateSelector(state).regeneratedJobs,
    shallowEqual
  );

  const showImagePage = useSelector(
    (state: any) => TranslateSelector(state).showImagePage,
    shallowEqual
  );

  const loadingPage = useSelector(
    (state: any) => TranslateSelector(state).translationPageLoading,
    shallowEqual
  );

  const editorValue = useCallback(
    (
      text,
      refEditor = outputRefEditor,
      id = 'translation-input',
      firstTimeCall = false
    ) => {
      // fix for extracting text from file
      if (id === 'translation-input' && text) {
        editorValueClear(refEditor, dispatch);
        editorValueUpdate(text, refEditor, id);
      }
      // id !== 'translation-input' is for when trigger new translation my input text stay
      if (id !== 'translation-input' || historyJob || firstTimeCall) {
        editorValueClear(refEditor, dispatch);
        editorValueUpdate(text, refEditor, id);
      }

      if (refEditor === outputRefEditor) {
        dispatch(resetTableImages());
      }
    },
    [dispatch, historyJob]
  );

  useEffect(() => {
    if (showImagePage) return;

    if (historyJob || transjob.jobId !== '-1') {
      editorValue(
        historyJob?.originaltext || transjob.inputText,
        inputRefEditor,
        'translation-input',
        true
      );
    }
  }, [
    editorValue,
    historyJob,
    showImagePage,
    transjob.inputText,
    transjob.jobId,
  ]);

  useEffect(() => {
    if (
      (!historyJob || transjob.jobId === '-1') &&
      regeneratedJobs?.length === 0
    ) {
      setShowFeedbackButtons(false);
    }
  }, [historyJob, regeneratedJobs?.length, transjob.jobId]);

  const transConfig = useSelector(
    (state: any) => TranslateSelector(state).transConfig,
    shallowEqual
  );

  const autoLangDetect = useSelector(
    (state: any) => TranslateSelector(state).autoLangDetect,
    shallowEqual
  );

  useEffect(() => {
    if (!heightlight) {
      resetHighlight(outputRefEditor);
    }
  }, [heightlight]);

  // check if the node_id and image_ul updated or not

  // Set input and output ref value
  useEffect(() => {
    outputEditor = outputRefEditor;
    inputEditor = inputRefEditor;

    const editorElementInput = document.getElementById('translation-input');
    const editorElementOupt = document.getElementById('translation-output');

    editorElementInput?.addEventListener('drop', (event) => {
      event.preventDefault();
    });

    editorElementOupt?.addEventListener('drop', (event) => {
      return event.preventDefault();
    });
  }, [outputRefEditor, inputRefEditor]);

  const handleFilterStatus = useCallback(
    (filterStatus: Number) => {
      if (filterStatus === 2) {
        dispatch(triggerAlert(t('contentfilter.unsafe'), 'error'));
        // setDisableInputField(false);
      } else if (filterStatus === 1) {
        dispatch(triggerAlert(t('contentfilter.sensitive'), 'error'));
        //   setDisableInputField(true);
        // } else {
        //   setDisableInputField(true);
      }
    },
    [dispatch, t]
  );

  const triggerFun = useCallback(() => {
    // console.log('triggerFun running');
    if (transjob.showModal) {
      setGiveFeedbackModalShow(true);
    }
    if (transjob) {
      // set output text
      editorValue(transjob?.simpleText, outputRefEditor, 'translation-output');
      props.setOutputText(transjob?.simpleText);

      // set input text
      // editorValue(transjob.inputText, inputRefEditor);
      // props.setInputText(transjob.inputText);
    }

    handleFilterStatus(transjob.filterStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    editorValue,
    handleFilterStatus,
    props.setOutputText,
    transjob.filterStatus,
    transjob.showModal,
    transjob?.simpleText,
  ]);

  useEffect(() => {
    if (transjob.jobId !== '-1') {
      triggerFun();
    }
  }, [transjob.jobId, triggerFun]);

  // Display selected history translation
  useEffect(() => {
    if (historyJob) {
      // get the ref element to input and output
      const { simpletextuseredited, simpletext, originaltext } = historyJob;
      let text = isEmpty(simpletextuseredited)
        ? simpletext
        : simpletextuseredited;
      editorValue(originaltext, inputRefEditor);
      editorValue(text, outputRefEditor, 'translation-output');
    }

    if (!historyJob && transjob.jobId === '-1') {
      editorValue('', outputRefEditor, 'translation-output');
    }

    // check if translation job is not  empty
    if (!historyJob && transjob.jobId !== '-1') {
      const { inputText, simpleText } = transjob;
      editorValue(inputText, inputRefEditor);
      editorValue(simpleText, outputRefEditor, 'translation-output');
    }
  }, [editorValue, historyJob, transjob, transjob.inputText, transjob.jobId]);

  //TODO update this to make api call
  const handleOutputLanguageTypeChange = (
    outputLanguageType: 'easy' | 'plain' | undefined
  ) => {
    dispatch(
      updateUserInfo({ preferred_output_language_type: outputLanguageType })
    );
    // localStorage.setItem('outputLanguageType', outputLanguageType);
  };

  const translateLanguage = useCallback(async () => {
    inputRefEditor.current.update(() => {
      $getRoot().selectStart();
    });

    dispatch(emptyStateValueSynonyms({ synonymsList: null }));
    dispatch(
      emptyStateValueTrans({
        regeneratedJobs: [],
        disableLikeDislike: {},
      })
    );
    props.setOpen(false);
    dispatch(sideBarTab(null));
    if (
      !checkInputLength(
        props.inputTextValue.current.text,
        dispatch,
        userDetails,
        t
      )
    ) {
      return;
    }
    editorValue(
      t('output.textarea.loading'),
      outputRefEditor,
      'translation-output'
    );
    props.setOutputText(t('output.textarea.loading'));

    // check if input text contains html tags (currently handling only links and bolding)
    let isHtmlTextInput = userDetails?.format_input_switch ? 'on' : 'off';

    // const inputTextPlain = props.inputTextValue.current.text;
    // const inputTextHtml = props.inputText;
    const inputTextArea = document.getElementById('translation-input');
    const jsonData = {
      inputText:
        isHtmlTextInput === 'on'
          ? inputTextArea?.innerHTML.replaceAll('\n', '<br/>')
          : inputTextArea?.innerText,
      currentUser: supabaseUser,
      translationLanguage:
        transConfig?.preferred_translation_language?.[
          `preferred_trans_language_${userDetails?.preferred_output_language_type}`
        ] === 'multi'
          ? autoLangDetect.value !== 'notfound' && autoLangDetect.value
            ? autoLangDetect.value
            : 'multi'
          : transConfig?.preferred_translation_language?.[
              `preferred_trans_language_${userDetails?.preferred_output_language_type}`
            ] ?? 'de',
      usedPlatform: 'SUMM webapp',
      outputLanguage: userDetails?.preferred_output_language_type || 'easy',
      input_text_type: isHtmlTextInput === 'on' ? 'html' : 'plain_text',
      is_new_lines: transConfig
        ? transConfig[
            `preferred_new_lines_${
              userDetails?.preferred_output_language_type ?? 'easy'
            }`
          ] === 'True'
        : true,
      separator_style: userDetails
        ? userDetails[
            `preferred_style_${
              userDetails?.preferred_output_language_type as 'easy' | 'plain'
            }`
          ]
        : 'hyphen',
      orignalInputText: inputTextArea?.innerHTML ?? '',
    };
    dispatch(updateJobValue(null));
    await dispatch(translateLangTrigger(jsonData));
    setShowFeedbackButtons(true);
    localStorage.removeItem('auto_detect');

    // Make curoser start at the begining when user translate
    // outputRefEditor.current.update(() => {
    //   $getRoot().selectStart();
    // });
  }, [
    dispatch,
    props,
    userDetails,
    t,
    editorValue,
    supabaseUser,
    transConfig,
    autoLangDetect,
  ]);

  const switchInput = (value: boolean) => {
    setFileInput(value);
  };

  useEffect(() => {
    dispatch(getTranslationConfig());
  }, [dispatch]);

  const feedBackButtonComp = useMemo(
    () => (
      <UserFeedback
        // inputText={props.inputText}
        outputTextValue={props.outputTextValue}
        outputText={props.outputText}
        outputRefEditor={outputRefEditor}
        setShowTooltipOutput={setShowTooltipOutput}
        // setShowInfoBorderOutput={setShowInfoBorderOutput}
        jobId={transjob.jobId}
        showFeedbackButtons={
          !loadingTranslation && !showImagePage && showFeedbackButtons
        }
        setShowFeedbackButtons={setShowFeedbackButtons}
        setDisableInputField={setDisableInputField}
        historyJob={historyJob}
      />
    ),
    [
      loadingTranslation,
      props.outputText,
      props.outputTextValue,
      showFeedbackButtons,
      transjob.jobId,
      historyJob,
      showImagePage,
    ]
  );

  // ui
  return (
    <Container
      maxWidth="xl"
      sx={{ padding: '0 !important', margin: 0, maxWidth: '100% !important' }}
    >
      {loadingPage && (
        <Box
          sx={{
            position: 'absolute',
            zIndex: 2000,
            top: 0,
            left: 0,
            width: '100%',
            height: '100vh',
            background: theme.palette.primary.main,
          }}
        >
          <CircularProgress
            size={'5rem'}
            aria-label={t('loading')}
            color={'secondary'}
            sx={{
              mt: '40vh',
            }}
          />
        </Box>
      )}
      <Box>
        <TranslationStepSwitch
          switchStep={(value) => dispatch(setImagePageVisibility(value))}
          addImage={showImagePage}
        />
        <Grid
          item
          container
          xs={12}
          sx={{ display: 'flex', justifyContent: 'end' }}
        >
          {!showImagePage && (
            <Grid
              item
              container
              xs={12}
              lg={6}
              sx={{ height: 'fit-content' }}
            >
              <TranslationInputWraper
                fileInput={fileInput}
                switchInput={switchInput}
                setInputText={props.setInputText}
                editorValue={editorValue}
                disableInputField={disableInputField}
                inputText={props.inputText}
                inputTextValue={props.inputTextValue}
                inputRefEditor={inputRefEditor}
              />
              <Grid
                item
                xs={12}
                sx={{ marginTop: '0em' }}
              >
                <TranslateButtonComp
                  translateLanguage={translateLanguage}
                  loadingTranslation={loadingTranslation}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            item
            container
            xs={12}
            lg={6}
            sx={{
              marginTop: '0',
              pt: matchesLG ? '1rem' : '0px',
            }}
          >
            <Grid
              item
              xs={12}
              sx={{ height: fileInput ? '100%' : 'auto' }}
            >
              <TranslationOutput
                showTooltipOutput={showTooltipOutput}
                outputText={props.outputText}
                outputTextValue={props.outputTextValue}
                setOutputText={props.setOutputText}
                handleOutputLanguageTypeChange={handleOutputLanguageTypeChange}
                outputLanguage={userDetails?.preferred_output_language_type}
                loadingTranslation={loadingTranslation}
                outputRefEditor={outputRefEditor}
                placeholder={t('output.textarea.placeholder')}
                jobId={transjob.jobId}
                isUpdated={isUpdated}
                setIsUpdated={setIsUpdated}
                showHighlight={true}
                open={props.open}
              />
              {feedBackButtonComp}
            </Grid>
          </Grid>
          {showImagePage && (
            <Grid
              item
              container
              xs={12}
              lg={6}
              sx={{
                mt: {
                  xs: 5,
                  lg: -3,
                },
              }}
            >
              <ImageSelectionComp />
            </Grid>
          )}
        </Grid>
        <GiveFeedbackModal
          show={giveFeedbackModalShow}
          onHide={() => setGiveFeedbackModalShow(false)}
          quotaLimit={userDetails?.quotalimit}
          quotaSpent={userDetails?.quotaspent}
        />
      </Box>
    </Container>
  );
};

export default TextareaField;
