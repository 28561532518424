import React, { useEffect } from 'react';
import PrivateRoute from './components/RouteHandling/PrivateRoute';
import BetaRoute from './components/RouteHandling/BetaRoute';
import Homepage from './components/Homepage/Homepage';
import Dataprivacy from './components/dataPrivacy/Dataprivacy';
import SignupPage from './components/signupForm/SignupPage';
import ForgotPassword from './components/resetPasswordForm/ForgotPassword';
import Dashboard from './components/Dashboard/Dashboard';
import TermsConditions from './components/termsConditions/TermsConditions';
import Imprint from './components/imprintPage/Imprint';
import TranslationHistory from './components/TranslationHistory/TranslationHistory';
import GlossaryEntries from './components/GlossaryEntries/GlossaryEntries';
import Footer from './components/footer/Footer';
import Health from './components/healthPage/Health';
import OptinWaitingPage from './components/optinWaitingPage/OptinWaitingPage';
import AccountOverview from './components/UserAccount/AccountOverview';
import ConfirmSignupComp from './components/confirmOTP/ConfirmOTP';
import { Route, useLocation, useNavigate } from 'react-router-dom';
import { SentryRoutes } from './config/sentryConfig';
import MyNavbarComp from './components/navbarComp/MyNavbarComp';
import { useSnackbar } from 'notistack';
import configSetup from './config/alertConfig';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { configSelector } from './redux/appSetting/selector';
import { getFeatures } from './redux/features/actions';
import { UserSelector } from './redux/user/selector';
import ImagePageWrapper from './components/ImagesPage/ImagePageWrapper';
import useTabIndex from './customeHooks/useTabIndex';
import './common.css';
import { FeatureSelector } from './redux/features/selector';
import NoRoutesPage from './components/404Page/NoRoutesPage';
import ShopPage from './components/ShopPage/ShopPage';
import CheckoutPage from './components/ShopPage/CheckoutPage';
import ThankYouPage from './components/ShopPage/ThankYouPage';
import TranslateTextPage from './components/TranslationPage/TranslateTextPage';
import { supabase } from 'src/config/supabase';
import Chatbot from './components/Chatbot/Chatbot';
import { getHistoryChat } from './redux/chatbot/actions';

function App() {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch: any = useDispatch();
  const location = useLocation();

  const history = useNavigate();
  useTabIndex();
  const { AlertMessage } = useSelector(
    (state: any) => configSelector(state),
    shallowEqual
  );
  const { trigger } = useSelector(
    (state: any) => configSelector(state),
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  const featuresList = useSelector(
    (state: any) => FeatureSelector(state).featuresList,
    shallowEqual
  );

  // clear old storage redux data
  useEffect(() => {
    if (localStorage.getItem('persist:root')) {
      localStorage.removeItem('persist:root');
    }

    if (userDetails?.previewFeatures && localStorage.getItem('chatId')) {
      dispatch(getHistoryChat());
    }
  }, []);

  useEffect(() => {
    if (trigger) {
      enqueueSnackbar(
        AlertMessage.msg,
        configSetup(AlertMessage.variant, AlertMessage.options)
      );
    }
  }, [trigger, AlertMessage, enqueueSnackbar, userDetails]);

  // call refreshToken every 5 seconds
  useEffect(() => {
    supabase.auth.startAutoRefresh();
    // return () => {
    //   supabase.auth.stopAutoRefresh();
    // };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // init matomo tag manager on launch
  useEffect(() => {
    // matomo tag manager
    let windowContreoller: any = window;
    if (!windowContreoller._mtm) {
      var _mtm = (windowContreoller._mtm =
        (windowContreoller._mtm as any) || []);
      _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
      var d = document,
        g = d.createElement('script'),
        s: any = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = `${process.env.REACT_APP_MATOMO_TAG_MANAGER_CONTAINER_URL}`;
      s.parentNode.insertBefore(g, s);
    }
    // plain js tracking (which is also used if container is registered as a default tracker in settings)
    if (!windowContreoller._paq) {
      var _paq = (windowContreoller._paq = windowContreoller._paq || []);
      _paq.push(['setDomains', ['*.summ-ai.com']]);
      _paq.push(['enableCrossDomainLinking']);
      _paq.push(['enableLinkTracking']);
    }
  }, []);

  useEffect(() => {
    //Fetch features list
    if (userDetails && !featuresList) {
      dispatch(getFeatures());
    }
    // Clear the supabase state valeu if user details value is null
  }, [dispatch, featuresList, userDetails]);

  // handle error ResizeObserver loop completed with undelivered notifications
  // this handle error show because of autofill extensions
  useEffect(() => {
    const errorHandler = (e: any) => {
      if (
        e.message.includes(
          'ResizeObserver loop completed with undelivered notifications'
          // || 'ResizeObserver loop limit exceeded'
        )
      ) {
        const resizeObserverErr = document.getElementById(
          'webpack-dev-server-client-overlay'
        );
        if (resizeObserverErr) {
          resizeObserverErr.style.display = 'none';
        }
      }
    };
    window.addEventListener('error', errorHandler);
    return () => {
      window.removeEventListener('error', errorHandler);
    };
  });

  useEffect(() => {
    // create a list to update purchase flow value true or false
    const listRelatedToPurchase = [
      '/confirm',
      '/checkout',
      '/signup',
      '/summ-products',
    ];
    if (!listRelatedToPurchase.includes(location.pathname)) {
      localStorage.removeItem('IN_PURCHASE_FLOW');
      localStorage.removeItem('signup_form');
    }

    if (location.pathname === '/whats-new') {
      history('/');
    }
  }, [history, location.pathname]);

  /* eslint-disable no-undef */
  return (
    <>
      <div
        className="App"
        style={{}}
      >
        <MyNavbarComp />
        <SentryRoutes>
          <Route
            path="/"
            // exact={true}
            element={<Homepage />}
          />
          <Route
            // exact
            path="confirm"
            element={<ConfirmSignupComp />}
          />
          <Route
            path="health"
            element={<Health />}
          />
          <Route
            path="dataprivacy"
            element={<Dataprivacy />}
          />
          <Route
            path="licence"
            element={<TermsConditions />}
          />
          <Route
            path="imprint"
            element={<Imprint />}
          />
          <Route
            path="signup"
            element={<SignupPage />}
          />
          <Route
            path="summ-products"
            element={<ShopPage />}
          />
          <Route
            path="checkout"
            element={<CheckoutPage />}
          />
          <Route
            path="thankyou"
            element={<ThankYouPage />}
          />
          <Route
            path="reset-password"
            element={<ForgotPassword />}
          />
          <Route
            path="optin-waiting"
            element={<OptinWaitingPage />}
          />
          <Route element={<PrivateRoute />}>
            <Route
              path="dashboard"
              element={<Dashboard />}
            />
            <Route
              path="account/:page?"
              element={<AccountOverview />}
            />
            <Route
              path="easy-language"
              element={<TranslateTextPage />}
            />
          </Route>
          <Route element={<BetaRoute />}>
            <Route
              path="translation-history"
              element={<TranslationHistory />}
            />
            <Route
              path="glossary-entries"
              element={<GlossaryEntries />}
            />
            <Route
              path="my-gallery"
              element={<ImagePageWrapper />}
            />
          </Route>
          <Route
            path="*"
            element={<NoRoutesPage />}
          />
        </SentryRoutes>
      </div>
      {userDetails?.previewFeatures && <Chatbot />}
      <Footer />
    </>
  );
}

export default App;
