import React from 'react';
import { Grid, useTheme } from '@mui/material';
import ImagePageListComp from './ImagePageListComp';
import { isEmpty } from 'lodash';
import { ImageUploadItem, ImageSearchCompProps } from './image.types';
import ImagePageSearchInputComp from '../../coreUI/searchComp/ImagePageSearchInputComp';
import CardCover from '@mui/joy/CardCover';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';
import SearchHintBox from '../imageUploadComp/SearchHintBox';

const ImageSearchComp = ({
  emptyStateValueImageUpload,
  searchUploadedImages,
  imageList,
  loading,
  langWords,
  downloadUploadedImage,
  feedbackImageUpload,
  deleteUploadedImage,
  selectedOutputNode,
  deletedImgId,
  handleInsertImage,
  isExpanded,
  imageSearchText,
  setImageSearchText,
}: ImageSearchCompProps) => {
  const { t } = useTranslation(['easy_language', 'common', 'A11y'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const handleSubmitSearch = (value: string) => {
    if (isEmpty(value)) {
      return emptyStateValueImageUpload({ imageList: null });
    }
    searchUploadedImages(value);
  };

  return (
    <Grid
      item
      container
      xs={12}
      spacing={2}
      id="image-search-box"
    >
      {loading && (
        <CardCover
          sx={{
            background: theme.palette.general.blockingOverlay,
            zIndex: 2,
          }}
        >
          <Box sx={{ width: '100%', height: '100%' }}>
            <LinearProgress
              sx={{
                position: 'absolute',
                width: '60%',
                background: theme.palette.secondary.main,
                '& .MuiLinearProgress-bar': {
                  background: theme.palette.primary.main,
                },
              }}
              aria-label={t('image.searching')}
            />
          </Box>
        </CardCover>
      )}
      {isExpanded && (
        <Grid
          item
          xs={12}
        >
          <ImagePageSearchInputComp
            handleSubmit={handleSubmitSearch}
            loading={loading}
            placeholder={langWords.searchImage}
            searchWord={langWords.searchWord}
            text={imageSearchText}
            setText={setImageSearchText}
            isVisible={isExpanded}
            inputId="image-search-input"
            buttonId="image-search-button"
          />
        </Grid>
      )}
      <Grid
        item
        xs={12}
      >
        {!imageList && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'flex-start',
              width: '100%',
              position: 'relative',
            }}
          >
            <SearchHintBox
              langWords={langWords}
              light={false}
            />
          </Box>
        )}
        {imageList && Object.values(imageList).length === 0 && (
          <div style={{ color: theme.palette.general.white }}>
            {langWords.noImage}
          </div>
        )}
        {imageList &&
          Object.values(imageList).length > 0 &&
          [
            ...Object.values(imageList?.ownerList ?? {}),
            ...Object.values(imageList?.otherList ?? {}),
          ].map(
            (imageItem: ImageUploadItem) =>
              Object.keys(imageItem?.images).length > 0 && (
                <ImagePageListComp
                  imageItem={imageItem}
                  key={imageItem.id}
                  downloadUploadedImage={downloadUploadedImage}
                  feedbackImageUpload={feedbackImageUpload}
                  deleteUploadedImage={deleteUploadedImage}
                  handleInsertImage={handleInsertImage}
                  selectedOutputNode={selectedOutputNode}
                  langWords={langWords}
                  deletedImgId={deletedImgId}
                />
              )
          )}
      </Grid>
    </Grid>
  );
};

export default ImageSearchComp;
