import React from 'react';
import { Grid, Link, Typography, useTheme } from '@mui/material';
import ImageListItemView from '../../coreUI/ImageListItemView/ImageListItemView';
import { ImageUploadItem, LangWords } from './image.types';

type Props = {
  imageItem: ImageUploadItem;
  downloadUploadedImage: (id: string) => void;
  feedbackImageUpload(
    id: string,
    feedback: 0 | 1 | 2,
    imageId: string | number,
    isOwner: boolean
  ): void;
  handleInsertImage?: (url: string) => void;
  deleteUploadedImage: (id: string, imageId: number, isOwner: boolean) => void;
  selectedOutputNode: number;
  langWords: LangWords;
  deletedImgId: string | null;
};

const ImagePageListComp = ({
  imageItem,
  downloadUploadedImage,
  feedbackImageUpload,
  deleteUploadedImage,
  handleInsertImage,
  selectedOutputNode,
  deletedImgId,
}: Props) => {
  // const theme = useTheme();

  const handleDownload = (id: string) => {
    downloadUploadedImage(id);
  };
  const handleFeedback = (id: string, feedback: 0 | 1 | 2) => {
    feedbackImageUpload(id, feedback, imageItem.id, imageItem.is_owner);
  };

  const handleDelete = (id: string) => {
    deleteUploadedImage(id, imageItem.id, imageItem.is_owner);
  };

  return (
    <Grid
      item
      container
      xs={12}
      mb={3}
    >
      <Grid
        item
        xs={12}
      >
        <ImageListItemView
          imageItem={imageItem}
          imagePage={true}
          handleDelete={handleDelete}
          handleDownload={handleDownload}
          handleFeedback={handleFeedback}
          handleInsertImage={selectedOutputNode ? handleInsertImage : undefined}
          disableInsertImage={selectedOutputNode ? false : true}
          deletedImgId={deletedImgId}
        />
      </Grid>
    </Grid>
  );
};

export default ImagePageListComp;
