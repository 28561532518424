import {
  $getSelection,
  $isRangeSelection,
  $isElementNode,
  $isTextNode,
  createCommand,
  $isParagraphNode,
  $createParagraphNode,
  $createTextNode,
  $getNodeByKey,
} from 'lexical';
import {
  $createTableCellNode,
  $createTableRowNode,
  $createTableNode,
  $isTableNode,
} from '@lexical/table';
import { $createQuoteNode, $createHeadingNode } from '@lexical/rich-text';
import { $createListItemNode, $createListNode } from '@lexical/list';
import { __createTableImageCellNode } from 'src/components/TextareaField/helper';
import store from 'src/redux/store';

// Define custom commands
export const SPLIT_TABLE_COMMAND = createCommand('SPLIT_TABLE_COMMAND');
export const MERGE_TABLE_COMMAND = createCommand('MERGE_TABLE_COMMAND');
export const DELETE_TABLE_COMMAND = createCommand('DELETE_TABLE_COMMAND');

// Function to determine the type of node and create a corresponding new one
function createTextNodeFromType(elementNode: any) {
  if ($isParagraphNode(elementNode)) return $createParagraphNode();
  if (elementNode.getType() === 'quote') return $createQuoteNode();
  if (elementNode.getType() === 'list') return $createListNode('bullet');
  if (elementNode.getType() === 'listitem') return $createListItemNode();
  if (elementNode.getType().startsWith('h'))
    return $createHeadingNode(elementNode.getType() as any);

  // Default to paragraph if the type is unknown
  return $createParagraphNode();
}

// Function to split a table at the cursor position
export const splitTableAtCursor = (editor: any) => {
  editor.update(() => {
    const selection = $getSelection();

    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      // Ensure the anchor node is inside a table cell
      // save the selected node type and this might be nested node type like (list > listitem)
      let tableCellNode: any = anchorNode?.getParent();
      while (tableCellNode && !$isElementNode(tableCellNode)) {
        tableCellNode = tableCellNode.getParent();
      }
      if (!tableCellNode) return;

      const tableRowNode = tableCellNode.getParent();
      let tableNode = tableRowNode?.getParent();
      while (!$isTableNode(tableNode)) {
        tableNode = tableNode?.getParent();
      }

      let getFirstChild = tableNode
        ?.getFirstChild()
        ?.getFirstChild()
        ?.getFirstChild();

      const cursorPosition = selection.anchor.offset;
      let textNode: any = anchorNode;

      if ($isElementNode(anchorNode)) {
        textNode = anchorNode?.getFirstChild(); // Get first child, which should be a text node
      }

      // create same node and instertit
      if ($isTextNode(textNode)) {
        const textContent = textNode.getTextContent();
        const beforeText = textContent.slice(0, cursorPosition);
        const afterText = textContent.slice(cursorPosition);
        // if (!isEmpty(afterText) && !isEmpty(beforeText)) {
        textNode.setTextContent(beforeText); // Update current text

        // Create a new table
        const newTable = $createTableNode();
        const newRow = $createTableRowNode();

        // Second column: New text content
        const newTextCell = $createTableCellNode(0);
        const newTextNode = createTextNodeFromType(tableCellNode);
        newTextNode.append($createTextNode(afterText));
        newTextCell.append(newTextNode);

        // Append cells to the new row
        newRow.append(newTextCell);
        newTable.append(newRow);

        // Insert the new table after the current one
        tableNode.insertAfter(newTable);
        if (getFirstChild.__type === 'image') {
          __createTableImageCellNode(
            '',
            newRow.getKey(),
            { current: editor },
            store.dispatch
          );
        }

        // Move the cursor to the new text node in the new table
        newTextNode.selectStart();
      }
      // }
    } else {
      const tableNode = sessionStorage.getItem('tableDOMElement');
      const hoveredTableNode = tableNode ? $getNodeByKey(tableNode) : null;
      if (hoveredTableNode) {
        const newTable = $createTableNode();
        const newRow = $createTableRowNode();
        const newCell = $createTableCellNode(0);
        const newTextNode = $createParagraphNode();
        newTextNode.append($createTextNode(''));

        newCell.append(newTextNode);
        newRow.append(newCell);
        newTable.append(newRow);
        hoveredTableNode.insertAfter(newTable);
        __createTableImageCellNode(
          '',
          newRow.getKey(),
          { current: editor },
          store.dispatch
        );
      }
    }
  });
};

export const deleteTable = (editor: any) => {
  editor.update(() => {
    const tableKey = sessionStorage.getItem('tableDOMElement');
    if (!tableKey) {
      return;
    }
    const tableNode = $getNodeByKey(tableKey);
    if (tableNode && $isTableNode(tableNode)) {
      tableNode.remove();
    }
  });
};
