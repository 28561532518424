import htmlDocx from 'html-docx-js/dist/html-docx';

const updatedHtmlString = async (htmlString: string) => {
  // Parse the HTML string into a DOM
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, 'text/html');

  // Select all <img> tags
  const imgElements = doc.querySelectorAll('img');
  let maxWidth = 0; // Store the maximum image width

  // Convert NodeList to an array and process each image asynchronously
  await Promise.all(
    Array.from(imgElements).map(
      (img) =>
        new Promise((resolve) => {
          const tempImg = new Image();

          // Set the image source
          tempImg.src = img.src;

          // Wait for the image to load
          tempImg.onload = () => {
            const naturalWidth = tempImg.naturalWidth / 1.5;
            const naturalHeight = tempImg.naturalHeight / 1.5;
            // Apply CSS width and height based on natural dimensions
            img.setAttribute('width', naturalWidth.toString());
            img.setAttribute('height', naturalHeight.toString());

            // Keep track of the maximum image width
            if (naturalWidth > maxWidth) {
              maxWidth = naturalWidth;
            }

            resolve(undefined); // Resolve when img is processed
          };

          // Handle broken images
          tempImg.onerror = () => resolve(undefined);
        })
    )
  );

  // Extract all <tr> elements from each table
  const allRows = Array.from(doc.querySelectorAll('table tr'));

  // Create a new table to store all merged rows
  const newTable = doc.createElement('table');
  newTable.setAttribute('border', '1'); // Optional: Keep border for structure
  newTable.style.width = `${100}%`; // Ensure uniform table width

  // Add extracted rows to the new table
  allRows.forEach((row) => {
    newTable.appendChild(row);
  });

  // Insert the new table at the end of the document body
  doc.body.appendChild(newTable);

  // Return the updated HTML as a string
  return doc.documentElement.outerHTML;
};

export const generateDocxFromHtml = async (
  htmlContent: string,
  filename: string = 'summ-ai'
) => {
  // console.log('htmlContent', htmlContent);
  // Define the header and footer for the Word document
  const header = `
   <html xmlns:o='urn:schemas-microsoft-com:office:office'
         xmlns:w='urn:schemas-microsoft-com:office:word'
         xmlns='http://www.w3.org/TR/REC-html40'>
   <head><meta charset='utf-8'><title>Document</title><style> 
   table {
      border: none;
      border-color:#fff;
      }

    table td, table th {
      border-color:#fff !important;
      }
        </style></head><body>`;
  const footer = `</body></html>`;

  // set fixed height for images
  htmlContent = await updatedHtmlString(htmlContent);
  // Combine the header, content, and footer
  const fullHtml = header + htmlContent + footer;

  // Create a Blob from the HTML string
  const blob = htmlDocx.asBlob(fullHtml, fullHtml);

  // Create a link element
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = `${filename}.docx`;

  // Append the link to the body
  document.body.appendChild(link);

  // Trigger the download
  link.click();

  // Remove the link from the document
  document.body.removeChild(link);
};
